<template>
  <div>
    <!-- Inputs de data -->
    <b-row>
      <b-col cols="6">
        <b-form-group label="Data Inicial">
          <b-form-input
            v-model="start_date"
            type="date"
            @input="getAll"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Data Final">
          <b-form-input
            v-model="end_date"
            type="date"
            @input="getAll"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card :title="`Painel de projeto - ${project_name}`" class="text-left">
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(item.projectResumeDashboard.projectCost)}`
            }}</strong>
            <small>Custos de Projetos </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.legalExpenses
              )}`
            }}</strong>
            <small>Despesas Legais </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.projectRevenue
              )}`
            }}</strong>
            <small>Receitas </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(item.projectResumeDashboard.direct_cost)}`
            }}</strong>
            <small>Custo Direto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.indirect_cost
              )}`
            }}</strong>
            <small>Custo Indireto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.projectTasksTotalCost
              )}`
            }}</strong>
            <small>Custo Total (Tarefas) </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.projectTotalCost
              )}`
            }}</strong>
            <small>Custo total do Projeto </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.contributionMargin
              )}`
            }}</strong>
            <small>Margem de contribuição</small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{
              `R$ ${numberToMonetary(
                item.projectResumeDashboard.projectResult
              )}`
            }}</strong>
            <small>Resultado </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{ item.projectResumeDashboard.profitability }}</strong>
            <small>Lucratividade </small>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <div class="dashboard-item">
            <strong>{{ item.projectResumeDashboard.hours }}</strong>
            <small>Horas no projeto </small>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Lucratividade -->

    <b-card
      v-if="profitability_data.render"
      :title="`Lucratividade`"
      class="text-left"
    >
      <b-row>
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <ProfitabilityChart
            v-if="profitability_data.render"
            :chartData="profitability_data"
        /></b-col>
      </b-row>
    </b-card>

    <!-- Receitas e Despesas -->

    <b-card
      v-if="
        revenue_data.render ||
        projectCost_data.render ||
        hoursMonth_data.render ||
        costsMonth_data.render ||
        hoursPerMember_data.render ||
        costsPerMember_data.render
      "
      :title="`Dashboard`"
      class="text-left"
    >
      <b-row class="mb-4">
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <label>Receitas</label>
          <ProfitabilityChart
            v-if="revenue_data.render"
            :chartData="revenue_data"
        /></b-col>

        <b-col cols="12" md="6" lg="4" class="text-center mx-auto flex">
          <label>Despesas (Custos de Projeto)</label>
          <ProfitabilityChart
            v-if="projectCost_data.render"
            :chartData="projectCost_data"
        /></b-col>
      </b-row>

      <!-- Horas Mes e  Custo de tarefas (Direto e Indireto)-->
      <b-row class="mb-4">
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <label>Horas mes</label>
          <ProfitabilityChart
            v-if="hoursMonth_data.render"
            :chartData="hoursMonth_data"
        /></b-col>

        <b-col cols="12" md="6" lg="4" class="text-center mx-auto flex">
          <label>Despesas (Custos de Projeto)</label>
          <ProfitabilityChart
            v-if="costsMonth_data.render"
            :chartData="costsMonth_data"
        /></b-col>
      </b-row>

      <!-- Horas por Colaborador no periodo e Custo Direto + Indireto por colaborador -->
      <b-row>
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto">
          <label>Horas por colaborador no período</label>
          <ProfitabilityChart
            v-if="hoursPerMember_data.render"
            :chartData="hoursPerMember_data"
        /></b-col>

        <b-col cols="12" md="6" lg="4" class="text-center mx-auto flex">
          <label>Custo Direto + Indireto por colaborador</label>
          <ProfitabilityChart
            v-if="costsPerMember_data.render"
            :chartData="costsPerMember_data"
        /></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip,
  BCol,
  BRow,
  BCard,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue';
import ProfitabilityChart from '../charts/Profitability.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    BCard,
    ProfitabilityChart,
    BFormInput,
    BFormGroup,
  },
  data: () => ({
    item: {
      projectResumeDashboard: {
        projectCost: 0,
        legalExpenses: 0,
        projectRevenue: 0,
        direct_cost: 0,
        indirect_cost: 0,
        projectTasksTotalCost: 0,
        projectTotalCost: 0,
        contributionMargin: 0,
        projectResult: 0,
        profitability: 0,
        hours: 0,
      },
    },
    project_name: 'Projeto',
    start_date: '2023-01-01',
    end_date: '2024-12-31',
    profitability_data: { render: false },
    revenue_data: { render: false },
    projectCost_data: { render: false },
    hoursMonth_data: { render: false },
    costsMonth_data: { render: false },

    hoursPerMember_data: { render: false },
    costsPerMember_data: { render: false },

    chartOptions: {
      chart: {
        id: 'bar',
        type: 'bar',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (number) {
            if (isNaN(number)) {
              return 'Invalid input';
            }
            if (number === 0) {
              return 'R$ 0,00';
            }
            if (number < 100) {
              const formattedCents = number.toFixed(2).padStart(5, '0');
              return `0,${formattedCents}`;
            }
            const [integerPart, decimalPart] = (number / 100)
              .toFixed(2)
              .toString()
              .split('.');
            const formattedIntegerPart = integerPart.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            );
            const result = `R$ ${formattedIntegerPart},${decimalPart}`;
            return result;
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 40,
        },
      },

      dataLabels: {
        enabled: true,
        formatter: function (number) {
          if (isNaN(number)) {
            return 'Invalid input';
          }
          if (number === 0) {
            return 'R$ 0,00';
          }
          if (number < 100) {
            const formattedCents = number.toFixed(2).padStart(5, '0');
            return `0,${formattedCents}`;
          }
          const [integerPart, decimalPart] = (number / 100)
            .toFixed(2)
            .toString()
            .split('.');
          const formattedIntegerPart = integerPart.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ','
          );
          const result = `R$ ${formattedIntegerPart},${decimalPart}`;
          return result;
        },
        offsetY: -20,
        style: {
          fontSize: '10px',
          colors: ['#304758'],
        },
      },
    },

    chartOptions_line: {
      chart: {
        id: 'bar',
        type: 'area',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 20,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
    },
  }),

  created() {
    this.getAll();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    // Entidades
    async getItem() {
      this.$store
        .dispatch('getProject', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_name = resp.project_name;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAll() {
      this.$store
        .dispatch('getProjectDashboard', {
          start_date: this.start_date,
          end_date: this.end_date,
          project_id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then(async (resp) => {
          this.getItem();
          await this.setProfitabilityChart(resp.monthlyProfitability);

          await this.setRevenueChart(resp.revenues);
          await this.setProjectCostChart(resp.projectCost);

          await this.setHoursMonthChart(resp.tasksByMonth);
          await this.setCostsMonthChart(resp.tasksByMonth);

          await this.setHoursPerMemberChart(resp.tasksCostAndHours);
          await this.setCostPerMemberChart(resp.tasksCostAndHours);

          this.item = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Lucratividade
    async setProfitabilityChart(monthlyProfitability) {
      const profitability = [];
      const date = [];
      monthlyProfitability.map((e) => {
        profitability.push(e.profitability);
        date.push(this.dateFormatter(e.date, 'MM/yy'));
      });

      this.profitability_data = {
        render: !profitability.length ? false : true,
        chartOptions: {
          ...this.chartOptions_line,

          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Custos do projeto',
            data: profitability,
          },
        ],
      };
    },

    // Receitas
    async setRevenueChart(revenues) {
      const data = [];
      const date = [];
      revenues.map((e) => {
        data.push(e.projectRevenue);
        date.push(this.dateFormatter(e.transaction_date, 'MM/yy'));
      });

      this.revenue_data = {
        render: !data.length ? false : true,
        chartOptions: {
          ...this.chartOptions,
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Receitas',
            data,
          },
        ],
      };
    },

    // Despesas (Custos de Projeto)
    async setProjectCostChart(revenues) {
      const data = [];
      const date = [];
      revenues.map((e) => {
        data.push(e.projectCost);
        date.push(this.dateFormatter(e.transaction_date, 'MM/yy'));
      });

      this.projectCost_data = {
        render: !data.length ? false : true,
        chartOptions: {
          ...this.chartOptions,
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Custo de projeto',
            data,
          },
        ],
      };
    },

    // Horas X Mes
    async setHoursMonthChart(value) {
      const data = [];
      const date = [];
      value.map((e) => {
        data.push(e.hours);
        date.push(this.dateFormatter(e.date, 'MMM/yy'));
      });

      this.hoursMonth_data = {
        render: !data.length ? false : true,
        chartOptions: {
          chart: {
            id: 'hours_month',
            type: 'bar',
          },
          xaxis: {
            categories: date,
          },
          plotOptions: {
            bar: {
              dataLabels: {
                orientation: 'vertical',
              },
              columnWidth: 20,
            },
          },
        },
        chartSeries: [
          {
            data,
          },
        ],
      };
    },

    // Custo direto por colaborador
    async setCostsMonthChart(value) {
      const data = [];
      const date = [];
      value.map((e) => {
        data.push(e.cost);
        date.push(this.dateFormatter(e.date, 'MM/yy'));
      });

      this.costsMonth_data = {
        render: !data.length ? false : true,
        chartOptions: {
          ...this.chartOptions,
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Custo de projeto',
            data,
          },
        ],
      };
    },

    // Horas por colobadorador
    async setHoursPerMemberChart(value) {
      const groupedData = this.groupDataByDateAndUser_hours(value);

      const series = Object.keys(groupedData).map((username) => ({
        name: username,
        data: Object.values(groupedData[username]),
      }));

      const data = [];
      const date = [];
      series.map((e) => {
        data.push(e.data[0]);
        date.push(e.name);
      });

      this.hoursPerMember_data = {
        render: date.length > 0,

        chartOptions: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '50px',
            },
            dataLabels: {
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: function (number) {
                return Math.floor(number);
              },
            },
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },

    // Custo por colobadorador
    async setCostPerMemberChart(value) {
      const groupedData = this.groupDataByDateAndUser_cost(value);

      const series = Object.keys(groupedData).map((username) => ({
        name: username,
        cost: Object.values(groupedData[username]),
      }));

      const cost = [];
      const name = [];
      series.map((e) => {
        cost.push(e.cost[0]);
        name.push(e.name);
      });

      this.costsPerMember_data = {
        render: series.length > 0,
        chartOptions: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '50px',
            },
            dataLabels: {
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100)
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = `R$ ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
          },
          xaxis: {
            labels: {
              formatter: function (number) {
                return Math.floor(number);
              },
            },
            categories: name,
          },
        },
        chartSeries: [
          {
            name: 'Custo',
            data: cost,
          },
        ],
      };
    },

    groupDataByDateAndUser_hours(data) {
      const groupedData = {};

      data.forEach((item) => {
        if (!groupedData[item.username]) {
          groupedData[item.username] = {};
        }

        const date = new Date(item.date).toISOString().split('T')[0];

        if (!groupedData[item.username][date]) {
          groupedData[item.username][date] = 0;
        }

        groupedData[item.username][date] += item.hours;
      });

      return groupedData;
    },

    groupDataByDateAndUser_cost(data) {
      const groupedData = {};

      data.forEach((item) => {
        if (!groupedData[item.username]) {
          groupedData[item.username] = {};
        }

        const date = new Date(item.date).toISOString().split('T')[0];

        if (!groupedData[item.username][date]) {
          groupedData[item.username][date] = 0;
        }

        groupedData[item.username][date] += item.cost; // Alterando de hours para cost
      });

      return groupedData;
    },
  },
};
</script>
<style scoped>
.dashboard-item {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
}
</style>
