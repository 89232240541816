<template>
  <div>
    <apexchart
      :options="chartData.chartOptions"
      :series="chartData.chartSeries"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartData: {
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
